<template>
  <div class="coursesPage font-17">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />
    <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024"
      img-height="480" style="text-shadow: 1px 1px 2px #333" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
      <b-carousel-slide v-for="banner in banners.result" :key="banner.id"
        :img-src="`https://elearningadmin.ordpmk.com/${banner.image_url}`"></b-carousel-slide>
    </b-carousel>
    <b-row class="justify-content-md-center mb-10">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="3" xl="3" class="layout-side-menu px-0 ">
        <sideMenu />
      </b-col>
      <b-col lg="9" xl="9" class="mt-10">
        <b-row>
          <b-col>
            <p style="font-size: 24px" class="pl-10">
              <b> {{ title }}</b>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="ebooks.length > 0" 
          align-v="stretch"
          class="mx-3  mx-sm-0 ml-sm-0 px-sm-3"
        >
          <b-col 
          
          sm="12" md="6" lg="6" xl="4" xxl="3" 
           v-for="view in ebooks" :key="view.id" 
           class="subject-card my-2 mb-10 px-0 px-sm-3"
          >
            <button id="myBtn" class="show-btn" @click="getFlipbook(view.id)">
              <div>
                <b-card-group>
                  <b-card overlay v-b-hover="handleHover" :img-src="view.image" img-alt="Image" img-top
                    img-height="500" class="hoverCard">
                    <div class="mycard">
                      <div class="positionButtom"></div>
                    </div>
                  </b-card>
                </b-card-group>
              </div>
            </button>
            <div class="margin">
              <b-button pill variant="warning" @click="downloadBook(view.id)">
                <b-icon icon="download"></b-icon>ดาวน์โหลด</b-button>
              <b-button variant="outline" class="button" @click="addfavorite(view)">
                <b-icon v-if="view.favorite" icon="heart-fill" style="color: #de7033"></b-icon>
                <b-icon v-else icon="heart" style="color: #de7033"></b-icon>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mb-10">
      <!-- <b-col class="col-xs-1"></b-col> -->
      <b-col>
        <b-pagination class="justify-content-md-end justify-content-sm-center justify-content-center mx-10 p-0"  v-model="paginate.current_page" :total-rows="paginate.total"
          :per-page="paginate.per_page" aria-controls="my-table" @input="getGroupByID()"></b-pagination>  
      </b-col>
      <!-- <b-col class="col-xs-1"></b-col> -->
    </b-row>

    <div id="myModal" class="modal" v-show="dialog">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close" @click="dialog = false">&times;</span>
        <div class="flip-book-container solid-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import { mapState } from "vuex";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";
import GroupAPI from "../service/api/GroupAPI";
import { imageDomain } from "../util/Constants"
import router from "../router";

export default {
  name: "ebookPage",
  components: {
    topNav,
    // sideMenuMobile,
    sideMenu,
    Loading,
  },
  computed: {
    ...mapState({
      menus: (state) => {
        return state.Courses.menus;
      },
      userData: (state) => state.Auth.userData,
    }),
  },
  async mounted() {
    if (this.$router.currentRoute.query.id == '1' && !this.userData.id) {
      alert("กรุณา Login");
      router.push({ path: "/login" })
    }
    this.isLoading = true;
    await this.getGroupByID();
    await this.getBookBanner();
    this.isLoading = false;
  },
  data() {
    return {
      filterid: "",
      isLoading: false,
      ebooks: [],
      title: "รายการ",
      isHovered: false,
      ebooksDefault: [],
      banners: [],
      paginate: {
        current_page: 1,
        total: 1,
        per_page: 1,
      },
      hoverid: null,
      dialog: false,
      imageDomain
    };
  },
  methods: {
    async getBookBanner() {
      this.banners = await GroupAPI.getBookBanner();
    },
    async getGroupByID() {
      const res = await GroupAPI.getGroupByID({
        groupid: this.$router.currentRoute.query.id,
        page: this.paginate.current_page,
        filterid: this.filterid,
      });
      this.ebooks = res.data;
      this.paginate = {
        current_page: res.current_page,
        total: res.total,
        per_page: res.per_page,
      };

      this.ebooksDefault = this.ebooks;
    },
    async addfavorite(item) {
      if (!this.userData.id) {
        alert("กรุณา Login");
      } else {
        if (item.favorite) {
          this.ebooks.forEach((e) => {
            if (e.id == item.id) {
              e.favorite = null;
            }
          });
          GroupAPI.deleteFavoriteBook(item.id);
        } else {
          this.ebooks.forEach((e) => {
            if (e.id == item.id) {
              e.favorite = item.id;
            }
          });
          GroupAPI.saveFavoriteBook(item.id);
        }
      }

    },
    async getFavoriteBook(page) {
      const res = await GroupAPI.getFavoriteBook({
        page: page,
        groupid:
          this.$router.currentRoute.query.id == 1
            ? ""
            : this.$router.currentRoute.query.id,
      });

      this.ebooks = res.data;
      this.paginate = {
        current_page: res.current_page,
        total: res.total,
        per_page: res.per_page,
      };
      this.ebooksDefault = this.ebooks;
    },
    async filterBook(id) {
      this.filterid = id;
      await this.getGroupByID();
    },

    async getFlipbook(id) {
      if (!this.userData.id) {
        alert("ต้อง Login ก่อน");
      } else {
        this.dialog = true;
        this.ebooks.filter((e) => {
          if (id == e.id) {
            // eslint-disable-next-line no-undef
            $(".flip-book-container").FlipBook({
              pdf:e.bookpdf,
              propertiesCallback: function (props) {
                props.page.depth *= 0.7;
                props.cover.padding = 0.002;
                return props;
              },
              controlsProps: {
                downloadURL: + e.bookpdf,
                actions: {
                  cmdSmartPan: {
                    enabled: true,
                    enabledInNarrow: true,
                  },
                },
              },
            });
          }
        });
      }
    },
    downloadBook(id) {
      this.ebooks.filter((e) => {
        if (id == e.id) {
          const downloadLink = document.createElement("a");
          const bookUrl = 'books/pdf/' + e.bookpdf + '.pdf';
          console.log(bookUrl);
          const filename = e.bookpdf + '.pdf';
          downloadLink.href = bookUrl;
          downloadLink.download = filename;
          downloadLink.target = "_blank";
          downloadLink.style.display = "none";

          document.body.appendChild(downloadLink);
          downloadLink.click();

          document.body.removeChild(downloadLink);
        }
      });
    },
    handleHover(isHovered) {
      this.isHovered = isHovered;
    },
  },
};
</script>
<style scoped>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}

.button {
  position: absolute;
  left: 80%;
}

.margin {
  padding: 10px;
}

.hoverCard {
  transition: 0.4s ease-out;
}

.hoverCard:hover {
  transform: translateY(20px);
}

.hoverCard:hover:before {
  opacity: 1;
}

.card:hover {
  transform: translateY(20px);
}

.hoverCard:hover:before {
  opacity: 1;
}

.hoverCard:hover .mycard {
  opacity: 0.5;
  transform: translateY(0px);
}

.mycard {
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-right {
  text-align: right;
}

.show-btn {
  position: relative;
  margin-top: auto;
  width: 100%;
  text-align: center;
  color: white;
}

.positionButtom {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.paginate {
  float: right;
}

.solid-container {
  height: 100vh;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .container {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}

.card-img, .card-img-top {
  height: revert-layer;
}
</style>
